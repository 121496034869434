<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        ref="form"
        :label-width="'110px'"
        label-position="right"
        size="large"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="loginName" :label="$t('add.用户账号') + '：'">
              <span>
                {{ deviceInfoForm.loginName }}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="userName" :label="$t('add.用户名称') + '：'">
              <span>
                {{ deviceInfoForm.userName }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="phone" :label="$t('add.手机号') + '：'">
              {{ deviceInfoForm.phone }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="status" :label="$t('add.状态') + '：'">
              {{
                deviceInfoForm.status === 1
                  ? $t("global.valid")
                  : $t("global.enable")
              }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="roleId" :label="$t('add.角色') + '：'">
              {{ deviceInfoForm.roleName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="orgId" :label="$t('add.部门') + '：'">
              {{ deviceInfoForm.orgName }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from './dialog'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
    }
  },
  created () {

  },
  methods: {

  }
}
</script>
