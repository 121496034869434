<script>
import api from '@/api'
import tool, { treearr } from '@/tool'
import base from '@/templates/list'
import MyDialog from './dialog'
import MyDialog2 from './dialog-info'
export default {
  directives: {},
  filters: {},
  components: {
    MyDialog,
    MyDialog2
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: tool.checkButton('user-add'), title: 'global.add' },
        remove: { show: tool.checkButton('user-remove'), title: 'global.remove' },
        edit: { show: tool.checkButton('user-edit'), title: 'global.edit' },
        view: { show: tool.checkButton('user-info'), title: 'global.details' },
        valid: { show: tool.checkButton('user-updateStatus-list') }
      },
      showList: [
        {
          copName: 'el-input',
          placeholder: 'list.登录名/用户名',
          key: 'query',
          name: '',
          attrs: ['placeholder']
        },
        {
          copName: 'el-select',
          key: 'status',
          type: '',
          attrs: ['placeholder', 'options'],
          placeholder: 'list.状态',
          options: [
            { label: 'global.启用', value: '0' },
            { label: 'global.停用', value: '1' }
          ]
        },
        {
          copName: 'el-select',
          key: 'roleId',
          type: '',
          attrs: ['placeholder', 'options'],
          placeholder: 'list.角色',
          options: [
          ]
        },
        {
          copName: 'el-cascader',
          placeholder: 'list.部门',
          key: 'orgId',
          attrs: ['placeholder', 'cascader', 'options', 'props', 'filterable'],
          filterable: true,
          showAllLevels: false,
          props: { checkStrictly: true, value: 'id', label: 'orgName', children: 'childrenList' },
          cascaderOptions: [
          ]
        }
      ],
      formInline: {
        query: '',
        status: '',
        name: '',
        roleId: '',
        orgId: '',
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      tableTile: [{
        key: '1',
        columnLabel: 'list.登录名',
        prop: 'loginName',
        width: ''
      }, {
        key: '2',
        columnLabel: 'list.用户名',
        prop: 'userName',
        width: ''
      }, {
        key: 'status',
        columnLabel: 'list.状态',
        prop: 'status',
        width: '',
        formatter: (row, column, cellValue, index) => {
          let temp = ''
          switch (cellValue) {
            case 0:
              temp = this.$t('global.启用')
              break
            case 1:
              temp = this.$t('global.停用')
              break
          }
          return temp
        }
      }, {
        key: '4',
        columnLabel: 'list.角色',
        prop: 'roleName',
        width: ''
      }, {
        key: '5',
        columnLabel: 'list.部门',
        prop: 'orgName',
        width: ''
      }]
    }
  },
  created () {
    api.organization.queryOrgTree({}).then(res => {
      this.showList[3].cascaderOptions = treearr(res.data)
    })
    api.auth.getAuthRoleAllRole().then(res => {
      this.showList[2].options = res.data.map(item => ({ ...item, label: item.roleName, value: item.id }))
    })
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      api.auth.getAuthUserList({
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline,
          orgId: this.formInline.orgId && this.formInline.orgId.length > 0 ? this.formInline.orgId[this.formInline.orgId.length - 1] : ''
        }
      }).then(res => {
        this.tableData = res.data.records
        this.formInline.page.total = res.data.total
        this.loading = false
      })
    },

    // 新增
    add () {
      this.$refs.myDialog.add()
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      await api.auth.getAuthDeleteUser({
        query: {
          id: id
        }
      })

      this.toPage(1)
    },

    // 编辑
    async edit (id) {
      const res = await api.auth.getAuthFindUser({ id })
      this.$refs.myDialog.edit({
        ...(res.data || {}),
        orgId: res.data.organizationId,
        roleIdList: res.data.roleId ? res.data.roleId.split(',') : []
      })
    },

    // 详情
    async view (id) {
      const res = await api.auth.getAuthFindUser({ id })
      this.$refs.myDialog2.view({
        ...(res.data || {}),
        orgId: res.data.organizationId,
        roleIdList: res.data.roleId ? res.data.roleId.split(',') : []
      })
    },

    // 启用
    async enable (id) {
      await this.$confirm(this.$t('global.enableTooltip'), { type: 'warning' })
      await api.auth.postAuthUpdateUserStatus({ id: id, status: 1 })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    },

    // 停用
    async disable (id) {
      await this.$confirm(this.$t('global.disableTooltip'), { type: 'warning' })
      await api.auth.postAuthUpdateUserStatus({ id: id, status: 0 })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    }
  }
}
</script>
