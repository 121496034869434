<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'720px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'150px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="loginName" :label="$t('add.邮箱账号') + '：'">
              <el-input
                size="small"
                v-model="deviceInfoForm.loginName"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="userName" :label="$t('add.用户名称') + '：'">
              <el-input
                size="small"
                autocomplete="off"
                v-model="deviceInfoForm.userName"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="phone" :label="$t('add.手机号') + '：'">
              <el-input
                size="small"
                v-model="deviceInfoForm.phone"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="roleIdList" :label="$t('add.角色') + '：'">
              <el-select
                filterable
                multiple
                size="small"
                v-model="deviceInfoForm.roleIdList"
                :placeholder="$t('global.请选择')"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.roleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              prop="orgId"
              class="orgSelect"
              :label="$t('add.部门') + '：'"
            >
              <el-cascader
                style="width: 180px"
                v-model="deviceInfoForm.orgId"
                :placeholder="$t('global.请选择')"
                filterable
                size="small"
                clearable
                :show-all-levels="false"
                :props="{
                  checkStrictly: true,
                  value: 'id',
                  label: 'orgName',
                  children: 'childrenList',
                }"
                :options="orgListTree"
              ></el-cascader>
              <span
                @click="refreshOrg"
                v-loading="refreshOrgLoading"
                style="margin-left: 20px"
              >
                {{ $t("global.刷新") }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isModelAdd">
          <el-col :span="24">
            <el-form-item :label="$t('add.默认密码') + '：'">
              <el-input
                style="width: 180px"
                v-model="deviceInfoForm.password"
                readonly
                size="small"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <span style="color: #0aa665; margin-left: 80px">
            {{ $t("add.发送默认密码") }}
          </span>
        </el-row>
        <!--        <el-row :gutter="20">-->
        <!--          <el-col :span="20">-->
        <!--            <el-form-item prop="status" :label="$t('add.状态') + '：'">-->
        <!--              <el-radio v-model="deviceInfoForm.status" :label="0">-->
        <!--                {{ $t("global.启用") }}-->
        <!--              </el-radio>-->
        <!--              <el-radio v-model="deviceInfoForm.status" :label="1">-->
        <!--                {{ $t("global.停用") }}-->
        <!--              </el-radio>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import md5 from 'js-md5'
import base from '@/templates/add'
import { treearr } from '@/tool'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        loginName: '',
        userName: '',
        phone: '',
        roleIdList: [],
        orgId: [],
        status: '',
        password: 'Admin~!1'
      },
      rules: {
        loginName: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/, message: this.$t('global.emailErrorMsg'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        userName: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        // password: [
        //   { required: true, message: this.$t('global.请输入密码'), trigger: ['blur', 'change'] },
        //   { pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, message: this.$t('global.formatErr'), trigger: 'blur' },
        //   { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        // ],
        confirmPwd: [
          { required: true, message: this.$t('global.请再次填写密码'), trigger: ['blur', 'change'] },
          { pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, message: this.$t('global.formatErr'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        phone: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /^1[0-9]{10}$/, message: this.$t('global.formatPhoneErr'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        roleIdList: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] }
        ],
        orgId: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] }
        ],
        status: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] }
        ]
      },
      orgListTree: [],
      roleList: [],
      copyPassword: '',
      refreshOrgLoading: false
    }
  },
  created () {
    this.getOrg()
    api.auth.getAuthRoleAllRole().then(res => {
      this.roleList = res.data
    })
  },
  methods: {
    getOrg () {
      api.organization.queryOrgTree({}).then(res => {
        this.orgListTree = treearr(res.data)
      })
    },
    refreshOrg () {
      if (this.refreshOrgLoading) return
      this.refreshOrgLoading = true
      api.organization.refreshOrgTree().then(res => {
        this.$message.success(this.$t('add.已刷新'))
        this.getOrg()

        this.refreshOrgLoading = false
      })
    },
    show () {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      this.titleTable = this.$t('add.新增用户')
      this.isModelAdd = true
      this.deviceInfoForm = {
        loginName: '',
        userName: '',
        phone: '',
        roleIdList: [],
        orgId: [],
        status: '',
        password: '000000'
      }
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('add.编辑用户')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data, orgId: data.orgId }
      this.copyPassword = this.deviceInfoForm.password
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('add.用户详情')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {}
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.isModelAdd) {
            // if (this.deviceInfoForm.password !== this.deviceInfoForm.confirmPwd) {
            //   return this.$message.warning('密码不一致')
            // }
          }
          this.posting = true

          var orgId = this.deviceInfoForm.orgId instanceof Array ? this.deviceInfoForm.orgId[this.deviceInfoForm.orgId.length - 1] : this.deviceInfoForm.orgId
          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id,
            password: this.isModelAdd ? md5(this.deviceInfoForm.password) : this.deviceInfoForm.password, // 密码
            orgId: orgId
          }

          try {
            await api.auth[this.isModelAdd ? 'postAuthAddUser' : 'postAuthUpdateUser'](query)
            this.posting = false
            this.$message.success(this.isModelAdd ? this.$t('add.addOk') : this.$t('global.submitOk'))
            this.close()
            this.$parent.onSubmit()
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.orgSelect {
  /deep/.el-form-item__content {
    display: flex;
    span {
      margin-left: 5px;
      white-space: nowrap;
      color: #0aa665;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
